import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { theme } from "../../global/theme";
import { useStore } from "../../hooks/use-store";
import { Store as StoreInterface } from "../../models/store";
import api from "../../services/api";
import { BASE_URL, LOGO_DEFAULT } from "../../utils/contants";

import {
  Address,
  Box,
  Container,
  ContainerContentList,
  ContainerOpacity,
  Content,
  InfoView,
  LinkToStore,
  SeeMoreSection,
  StoreDetails,
  StoreLogo,
  StoreTitle,
  TextApartmentComplex,
} from "./styles";
import { useCart } from "../../hooks/use-cart";
import useStores from "../../store/stores/useStores";
import { Store } from "@inhouse-market/sdk";
import { useDebouncedCallback } from "use-debounce";
import { FlashList } from "@shopify/flash-list";

interface HeaderProps {
  title?: string;
  hideBackBtn?: boolean;
  backgroundColor?: string;
  isTitleCentered?: boolean;
  visibleHeader?: boolean;
  hideModalAndShowHeader?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  visibleHeader,
  hideModalAndShowHeader,
}) => {
  const paramStore = useStore();
  const cart = useCart();
  const [store, setStore] = useState<StoreInterface>({} as StoreInterface);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchProduct, setSearchProduct] = useState("");

  const {
    data: stores,
    fetchNextPage: fetchNextPageStores,
    hasNextPage: hasNextPageStores,
    isFetching: isFetchingStore,
  } = useStores(
    Number(paramStore.currentLatitude),
    Number(paramStore.currentLongitude),
    paramStore.shopmanagerId,
    searchQuery
  );

  const storeList = stores?.pages.flatMap(
    (page) => page.data.filter((item) => !!item.is_open) || []
  );

  const getStoreData = async () => {
    try {
      if (paramStore.storeId) {
        const storeTemp = await api.get<StoreInterface>(
          `/store/${paramStore.storeId}`
        );
        setStore(storeTemp.data);
        cart.setStore(storeTemp.data);
      } else {
        if (hideModalAndShowHeader) {
          hideModalAndShowHeader();
        }
      }
    } catch (error: any) {
      if (hideModalAndShowHeader) {
        hideModalAndShowHeader();
      }
    }
  };

  const handleLoadMoreStores = () => {
    if (hasNextPageStores && !isFetchingStore) {
      fetchNextPageStores();
    }
  };

  const checkSearchOpen = async () => {
    const headerOpen = await AsyncStorage.getItem("@openHeader");
    if (headerOpen === "true" || visibleHeader === true) {
      setSearchBarOpen(true);
    }
  };

  const delayingApiCall = useDebouncedCallback(
    (productName: string) => setSearchQuery(productName),
    500
  );

  useEffect(() => {
    getStoreData();
    checkSearchOpen();
  }, [visibleHeader]);

  const renderItem = ({ item }: { item: Store.Store }) => (
    <ContainerContentList key={item.id}>
      <LinkToStore href={`${BASE_URL}?store=${item.id}`}>
        <TextApartmentComplex>{item.name}</TextApartmentComplex>
      </LinkToStore>
    </ContainerContentList>
  );

  return (
    <>
      <Container backgroundColor={theme.colors.tertiary}>
        <Content>
          <Box size={1.0} alignItems="center">
            <StoreLogo
              key={store.id}
              source={{ uri: store?.logo || LOGO_DEFAULT }}
              testID="logo"
            />
            <InfoView>
              <StoreTitle numberOfLines={1} ellipsizeMode="tail" testID="name">
                {store.name}
              </StoreTitle>

              <Address testID="address">{store?.address?.street}</Address>
            </InfoView>
          </Box>
        </Content>
      </Container>
      {/* {searchBarOpen && (
        <SeeMoreSection>
          <StoreDetails>
            <Searchbar
              placeholder="Digite o nome da unidade"
              onChangeText={(text) => {
                delayingApiCall(text);
                setSearchProduct(text);
              }}
              value={searchProduct}
              style={{
                borderRadius: 10,
                fontFamily: theme.fonts.regular,
                fontSize: 15,
                marginBottom: 20,
              }}
            />
            <View style={{ flex: 1, position: "relative" }}>
              <FlashList
                data={storeList}
                renderItem={renderItem}
                keyExtractor={(item, index) => `${index}-${item.id}`}
                onEndReached={handleLoadMoreStores}
                onEndReachedThreshold={0.5}
                estimatedItemSize={100}
                ListFooterComponent={() =>
                  isFetchingStore ? (
                    <ActivityIndicator
                      color={theme.colors.primary}
                      size={25}
                      style={{ marginTop: 30 }}
                    />
                  ) : null
                }
              />
            </View>
          </StoreDetails>
        </SeeMoreSection>
      )} */}
      {/* <ContainerOpacity
        style={{
          display: searchBarOpen === true ? "flex" : "none",
        }}
      /> */}
    </>
  );
};
