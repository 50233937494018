import { useInfiniteQuery } from '@tanstack/react-query';
import { apiSDK } from '../../services/apiSDK';
import { queryKeys } from '../../services/query';


export default function useStores(latitude: number, longitude: number, shopmanagerId?: string | null, name?: string) {
  const queryFn = async ({ pageParam = 1 }: { pageParam?: number }) => {

    const { data, meta } = await apiSDK.store.search({
			distance: 9999999999999,
			type: 'totem',
			latitude,
			longitude,
      name,
      page: pageParam,
      order: 'name',
      ...(shopmanagerId && { shopmanagerId: Number(shopmanagerId) })
		})
        
    return {
      data,
      meta,
    }
  };

  return useInfiniteQuery({
    queryFn,
    queryKey: queryKeys.STORES(latitude, longitude, name),
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.current_page === lastPage.meta.last_page) return undefined;
      return lastPage.meta.current_page + 1;
  },
  });
}