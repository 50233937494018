import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import {
	Container,
	ContainerRow,
	Content,
	IconContainer,
	Icon,
	TextButton,
	Version,
	ContainerStatus,
	TitleKips,
	ValueKips,
	Title,
} from "./styles";

import pkg from "../../../app.json";
import HeaderNavigation from "../../components/HeaderNavigation";
import ModalSupport from "../../components/ModalSupport";
import { useShellData } from "../../providers/shell-provider";
import { ScrollView } from "react-native";

const CartEmpty = require("../../assets/cart_clean.svg");
const MachineSetting = require("../../assets/setting.svg");
const Support = require("../../assets/support.svg");

type Navigation = NavigationProp<any>;

const Others: React.FC = () => {
	const navigation = useNavigation<Navigation>();
	const [openSupport, setOpenSupport] = useState(false);

	const handleChangeToMyBuys = () => {
		navigation.navigate("MyBuys" as never);
	};

	const shellData = useShellData();

	const sendOpenMachineEvent = () => {
		window.ReactNativeWebView?.postMessage(JSON.stringify({
			type: "OPEN_TOTEM_CONFIG",
		}));
	}

  const posConfs = [
    { title: 'PWA', Value: pkg?.expo?.version || "1.0.0" },
		{ title: 'Shell', Value: shellData?.version || "1.0.0" },
		{ title: 'POS ID', Value: shellData?.pos.device?.name || "0" },
		{ title: 'POS Serial', Value: shellData?.pos?.device?.id || "0" },
		{ title: 'ID da Loja', Value: shellData?.storeId || "0" },
    { title: 'UUID do Android', Value: shellData?.deviceId || "0" },
    { title: 'POS Autenticado', Value: !!shellData?.pos?.authenticated },
    { title: 'POS Pareado', Value: !!shellData?.pos?.paired },
    { title: 'POS Próximo', Value: !!shellData?.pos?.nearby },
    { title: 'Bluetooth', Value: !!shellData?.pos?.bluetoothAccessPermission },
    { title: 'Arquivo', Value: !!shellData?.pos?.fileAccessPermission },
    { title: 'Localização', Value: !!shellData?.pos?.locationAccessPermission },
    { title: 'Telefone', Value: !!shellData?.pos?.phoneAccessPermission },
  ];

	return (
		<>
			<Container>
				<HeaderNavigation enableButtonLeft={true} enableButtonRight={false} title="Outros" />
				<ContainerRow onPress={handleChangeToMyBuys} style={{ marginTop: 22 }}>
					<IconContainer>
						<Icon
							resizeMode="contain"
							source={CartEmpty}
						/>
					</IconContainer>
					<TextButton>Comprovantes de compra</TextButton>
				</ContainerRow>
				<ContainerRow onPress={() => setOpenSupport(true)}>
					<IconContainer>
						<Icon
							resizeMode="contain"
							source={Support}
						/>
					</IconContainer>
					<TextButton>Suporte</TextButton>
				</ContainerRow>
				{shellData && (
					<ContainerRow onPress={() => sendOpenMachineEvent()}>
						<IconContainer>
							<Icon
								resizeMode="contain"
								source={MachineSetting}
							/>
						</IconContainer>
						<TextButton>Configurações do totem</TextButton>
					</ContainerRow>
				)}
				{shellData && (
					<ScrollView showsVerticalScrollIndicator={false} style={{ marginBottom: 5 }}>
						<Version>
							<Content>
								<Title>Status do totem</Title>
							</Content>
							{posConfs.map((item, index) => (
								<ContainerStatus key={index} isFirst={index === 0}>
									{item.title === 'Permissões' ?
										<TitleKips key={index} bold>{item.title}</TitleKips> :
										<TitleKips key={index}>{item.title}</TitleKips>
									}
									<ValueKips border={item.title !== 'Permissões'}>
										{typeof item.Value !== "boolean" ? item.Value : item.Value ? "Sim 🟢" : "Não 🔴"}
									</ValueKips>
								</ContainerStatus>
							))}
						</Version>
					</ScrollView>
				)}
				<ModalSupport isOpen={openSupport} setOpen={setOpenSupport} />
			</Container>
		</>
	);
};

export default Others;

