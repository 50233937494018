import env from "../env";

// PURCHASE
export const ENV = "prod";
export const TIME_TO_CANCEL_PIX_INVOICE = 1000 * 60 * 5; // 5mins
export const TIME_TO_UPDATE_PURCHASE_STATUS = 5 * 1000; // 5 segs
export const DEFAULT_RADIUS_DISTANCE = 500;
//FIREBASE
export const APIKEY = "AIzaSyB0oyfPDpI8CD2ISVoGXZ7_owJlJzcBlXI"
export const AUTHDOMAIN = "shoppbud-totem-cd2c7.firebaseapp.com"
export const PROJECTID = "shoppbud-totem-cd2c7"
export const STORAGEBUCKET = "shoppbud-totem-cd2c7.appspot.com"
export const MESSAGINGSENDERID = "562981427162"
export const APPID = "1:562981427162:web:d7be2f74d77bceaa1b52e3"
export const MEASUREMENTID = "G-07NJCQ9N37"
export const MESSAGINGTOKEN = "BGUjguVheR6AwOTMsGEHS0dPYXrOHviBGr5HmC2NNIhlDNcIypEOwpPH2-4ib_3aBA22IrY2rY5IjUDvmZfidY0"
//@ts-ignore
export const BASE_URL = ENV == "prod" ? env.REDIRECT_URL : `http://localhost:19006`;
export const SENTRY_DSN = 'https://a655454d39a5424e89e3417eb2c094b5@o4505200927440896.ingest.us.sentry.io/4505563243151360';
export const LOGO_DEFAULT = "https://storage.googleapis.com/api-prd-storage/store/519c9b80-54f7-44d4-ade7-9a0fa8c53e35.jpeg"

