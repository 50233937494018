import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/core";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { Header } from "../../components/Header";

import ProductClass from "../../components/ProductsClass";
import Carrossel from "../../components/Carrossel/Carousel";
import SearchButton from "../../components/SearchButton";
import { useStore } from "../../hooks/use-store";
import { Store as StoreInterface } from "../../models/store";
import pkg from "../../../app.json";

import {
  CategoriesContent,
  Container,
  Content,
  EmptyMessage,
  EmptyStore,
  TextCategories,
} from "./styles";
import useCategories from "../../store/category/useCategories";
import { Category as CategoryService } from "@inhouse-market/sdk";
import { FlatList, View } from "react-native";
import useAdverts from "../../store/adverts/useAdverts";
import { ButtonContainer, TextLoadingCategory } from "../category/styles";
import { useGetPaymentMethod } from "../../store/payments/payment-methods";
import ModalSupportStore from "../../components/ModalSupport/suport.store";

type Navigation = NavigationProp<any>;

const Home: React.FC = () => {
  const [visibleHeader, setVisibleHeader] = useState(false);
  const [store] = useState<StoreInterface>({} as StoreInterface);
  const [openSupport, setOpenSupport] = useState(false);

  const isFocused = useIsFocused();
  const navigation = useNavigation<Navigation>();
  const paramStore = useStore();

  useGetPaymentMethod(paramStore.storeId);

  const {
    data: category,
    isFetching: isFetchingCategory,
    isLoading: isLoadingCategory,
    hasNextPage: hasNextPageCategory,
    fetchNextPage: fetchNextPageCategory,
  } = useCategories(Number(paramStore.storeId), undefined);

  const { data: adverts } = useAdverts({
    showIn: 'INSIDESTORE', storeId: Number(paramStore.storeId),
  });

  const hideModalAndShowHeader = () => {
    setOpenSupport(true)
    setVisibleHeader(true)
  };

  const categoryList = useMemo<CategoryService.CategoryItem[] | null>(() => {
    if (!category) {
      return [];
    }

    return category?.pages.flatMap((page) => page.data) || [];
  }, [category]);

  const keyExtractor = useCallback(
    (item: CategoryService.CategoryItem, i: number) => `${i}-${item.id}`,
    []
  );

  const handleNavigateToScreen = (
    screenName: string,
    params: Object | undefined = undefined
  ) => {
    navigation.navigate(screenName, {
      params,
    });
  };

  useEffect(() => {
    AsyncStorage.setItem("@openHeader", "false");
  }, []);

  useEffect(() => {
    if (!isFocused) return;
    if (paramStore.storeId === "") setOpenSupport(true);
  }, [isFocused]);

  const verifyAndCreateUUID = React.useCallback(async () => {
    const item = await AsyncStorage.getItem("id_pwa");
    if (item) return;
    AsyncStorage.setItem("id_pwa", uuidv4());
  }, []);

  const onEndReachedCategory = () =>
    hasNextPageCategory && fetchNextPageCategory();

  useEffect(() => {
    verifyAndCreateUUID();
  }, []);

  useEffect(() => {
    const sendEventWebView = () => {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: "NAVIGATION_STATE_CHANGE",
          payload: {
            routerName: "Loja",
            versionName: pkg?.expo?.version,
          },
        })
      );
    };
    sendEventWebView();
  }, []);

  return (
    <>
      <Header
        visibleHeader={visibleHeader}
        hideModalAndShowHeader={hideModalAndShowHeader}
      />
      <Container>
        <Content>
          <View style={{ paddingHorizontal: 25, marginVertical: 17 }}>
            <FlatList
              data={categoryList}
              showsVerticalScrollIndicator={false}
              horizontal
              keyExtractor={keyExtractor}
              renderItem={({ item }) => (
                <View style={{ height: 35 }}>
                  <CategoriesContent
                    selected={false}
                    key={item.id}
                    onPress={() =>
                      handleNavigateToScreen("Categorias", {
                        name: item.name,
                        id: item.id,
                      })
                    }
                  >
                    <TextCategories selected={false}>
                      {item.name}
                    </TextCategories>
                  </CategoriesContent>
                </View>
              )}
            />
          </View>
          <FlatList
            ListHeaderComponent={
              adverts?.length && categoryList?.length ? (
                <Carrossel
                  item={adverts}
                  handleNavigateTo={(ads) => handleNavigateToScreen("Ad", { ads })}
                />
              ) : null
            }

            ListFooterComponent={
              <>
                <ButtonContainer>
                  {isFetchingCategory && isLoadingCategory && (
                    <TextLoadingCategory>Carregando...</TextLoadingCategory>
                  )}
                </ButtonContainer>
              </>
            }
            contentContainerStyle={{ paddingBottom: 30 }}
            data={categoryList}
            keyExtractor={keyExtractor}
            showsHorizontalScrollIndicator={false}
            onEndReached={onEndReachedCategory}
            renderItem={({ item }) => (
              <ProductClass
                category={item}
                storeId={Number(paramStore.storeId)}
                store={store}
              />
            )}
          />
          {isFetchingCategory && !isLoadingCategory && (
            <ButtonContainer>
              <TextLoadingCategory>Carregando Produtos...</TextLoadingCategory>
            </ButtonContainer>
          )}
        </Content>
        {!categoryList?.length && !isLoadingCategory && !visibleHeader && (
          <EmptyStore>
            <EmptyMessage>Não há produtos cadastrados nessa loja</EmptyMessage>
          </EmptyStore>
        )}
        {visibleHeader && (
          <EmptyStore>
            <EmptyMessage>Loja não encontrada, inativa ou fechada no momento.</EmptyMessage>
          </EmptyStore>
        )}
        {categoryList && categoryList?.length > 0 && <SearchButton />}
      </Container>
      <ModalSupportStore
        isOpen={openSupport}
        setOpen={setOpenSupport}
      />
    </>
  );
};

export default Home;
