import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.colors.white};
`;

export const Content = styled.View`
	border-top-width: 1px;
	border-top-color: #D7D7D7;
	padding: 30px 0px 17px 0px;
`;

export const PixValueContainer = styled.View`
	margin-bottom: 26px;
`;

export const Value = styled.Text`
	font-family: ${theme.fonts.bold};
	color: ${theme.colors.black};
	font-size: 20px;
`;

export const ContainerRow = styled.TouchableOpacity`
	flex-direction: row;
	padding: 18px 20px 18px 37px;
	align-items: center;
	height: 67px;
`;
export const ContainerStatus = styled.View<{ isFirst?: boolean }>`
  padding: 0px 10px;
  height: 45px;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #D7D7D7;
  border-left-width: 1px;
  border-right-width: 1px;
  ${({ isFirst }) => isFirst && 'border-top-width: 1px;'}
`;
export const IconContainer = styled.View`
	margin-right: 20px;
`;

export const Icon = styled.Image`
	width: 21px;
	height: 21px;
`;

export const TextButton = styled.Text`
	font-size: 13px;
	color: ${theme.colors.tertiary};
`;

export const TitleKips = styled.Text<{ bold?: boolean }>`
  flex: 1; 
  font-size: 12px;
  color: #000000;
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
  line-height: 14.93px;
	text-align: start;
`;

export const ValueKips = styled.Text<{ border?: boolean }>`
  width: 45%; 
  font-size: 13px;
  color: #30374A;
  font-weight: 500;
  line-height: 18.51px;
  text-align: center; 
  border-color: #D7D7D7;
	height: 100%;
	display: flex; 
	justify-content: center;
	align-items: center;
	${({ border }) => border && 'border-left-width: 1px;'}
`;

export const Title = styled.Text`
	font-size: 16px;
	color: #000000;
	font-weight: 600;
	line-height: 22.78px;
	text-align: start;
`;

export const Version = styled.View`
	width: 100%;
	padding: 0px 37px 0px 37px;
	flex: 1;
	justify-content: flex-start;
	margin-top: 22px;
`;

export const VersionText = styled.Text`
	font-size: 12px;
	font-weight: bold;
	color: ${theme.colors.gray};
`;
export const ContainerWithoutFeedBack = styled.TouchableOpacity`
	flex: 1;
	justify-content: center;
	align-items: center;
`;
export const ContainerTouch = styled.View`
	justify-content: center;
	align-items: center;
	border-width: 1px;
	border-color: ${theme.colors.white};
	width: 100%;
	height: 220px;
	background-color: ${theme.colors.white};
`;

